<template>
  <div>
    <row-select v-for="row in content.body" :key="row.id" :row="row"/>

    <div class="sm:flex flex-wrap justify-center px-4 lg:px-8">
      <div v-for="(chapter, i) in chapters" :key="chapter.id" class="sm:w-1/2 lg:w-1/3 sm:px-4 lg:px-8 mb-16">
        <router-link :to="chapterLink(i)" class="block">
          <div class="pb-aspect-9/16 relative">
            <app-image :img="chapter.data.thumbnail" class="absolute object-cover w-full h-full"/>
          </div>
          <p class="text-center font-serif text-lg lg:text-xl mt-3 mb-8">
            <span>{{ i + 1 }}.</span> {{ chapter.data.title }}
          </p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import store from '@/store'

  export default {
    name: 'Consultations',
    data () {
      return {
        title: 'Public consultations'
      }
    },
    computed: {
      page () {
        return store.state.consultation
      },
      content () {
        return this.page?.data
      },
      chapters () {
        return store.state.chapters
      },
      chapterIDs () {
        return store.getters.chapterIDs
      },
      chapterPaths () {
        return store.getters.chapterPaths
      },
      version () {
        return this.$route.params.version
      }
    },
    methods: {
      chapterLink (i) {
        return `/consultations/${this.version}/${this.chapterPaths[i]}`
      }
    },
    beforeRouteEnter (to, from, next) {
      const { version } = to.params
      store.dispatch('getConsultation', version).then(next)
    },
    beforeRouteUpdate (to, from, next) {
      const { version } = to.params
      store.dispatch('getConsultation', version).then(next)
    },
    mounted () {
      store.commit('SEO', this.content)
    }
  }
</script>
